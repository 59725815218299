import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { openDB } from 'idb';
import './TonWalletChecker.css';



const NFTDisplay = ({ nfts }) => {
  if (nfts.length === 0) {
    return <p>No NFTs found.</p>;
  }

  return (
    <div className="nft-grid compact">
      {nfts.map((nft, index) => (
        <a
          key={index}
          href={`https://tonviewer.com/${nft.address || ''}`}
          target="_blank"
          rel="noopener noreferrer"
          className="nft-card compact"
        >
          {nft.previews && nft.previews[1]?.url ? (
            <img
              src={nft.previews[1].url}
              alt={nft.metadata?.name || 'NFT'}
              className="nft-image compact"
            />
          ) : (
            <div className="no-preview compact">No Preview</div>
          )}
          <div className="nft-details compact">
            <div className="nft-name compact">{nft.metadata?.name || 'Unnamed NFT'}</div>
            <div className="nft-collection compact">{nft.collection?.name || 'Unknown Collection'}</div>
          </div>
        </a>
      ))}
    </div>
  );
};
const TokenAutocomplete = ({ value, onChange, suggestions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [selectedExactToken, setSelectedExactToken] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    // Закрытие выпадающего списка при клике вне компонента
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setSelectedExactToken('');
    onChange({ input: newValue, exact: '' });
    setIsOpen(true);
  };

  const handleSelect = (token) => {
    setInputValue(token);
    setSelectedExactToken(token);
    onChange({ input: token, exact: token });
    setIsOpen(false);
  };

  const highlightMatch = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === query.toLowerCase() ? (
            <span key={index} className="highlight">{part}</span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <div className="token-autocomplete" ref={dropdownRef}>
      <div className="token-input-wrapper">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          placeholder="Enter jetton name"
          className="token-input"
        />
        {selectedExactToken && (
          <button
            className="clear-selection"
            onClick={() => {
              setInputValue('');
              setSelectedExactToken('');
              onChange({ input: '', exact: '' });
            }}
          >
            ×
          </button>
        )}
      </div>
      {isOpen && suggestions.length > 0 && (
        <div className="token-suggestions">
          {suggestions.map((token, index) => (
            <div
              key={index}
              className={`token-suggestion-item ${token === selectedExactToken ? 'selected' : ''}`}
              onClick={() => handleSelect(token)}
            >
              {highlightMatch(token, inputValue)}
              {token === selectedExactToken && (
                <span className="selected-indicator">✓</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
const TonWalletChecker = () => {
  const [walletAddresses, setWalletAddresses] = useState(['']);
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState({ processedCount: 0, totalWallets: 0 });
  const [totalBalance, setTotalBalance] = useState(0);
  const [selectedJetton, setSelectedJetton] = useState('');
  const [sortedDescending, setSortedDescending] = useState(false);
  const [totalJettonBalances, setTotalJettonBalances] = useState({});
  const [availableTokens, setAvailableTokens] = useState([]);
  const [sortedJettonsDescending, setSortedJettonsDescending] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [, setShowNFTs] = useState(false);
  const [nftData, setNftData] = useState({});
  const [loadingNFTs, setLoadingNFTs] = useState(false);
  const [db, setDb] = useState(null); // Храним экземпляр базы данных
  const [activeTab, setActiveTab] = useState('tokens'); 
  // Cache setup using local storage
  const ttl = 60 * 60 * 1000; // Default TTL is 60 minutes (in milliseconds)
  const TransactionHistory = ({ transactions }) => {
    if (!transactions || transactions.length === 0) {
      return <p className="text-gray-400 py-4 text-center">No transactions found.</p>;
    }
  
    const formatAddress = (address) => {
      if (!address || typeof address !== "string" || address === "Unknown") {
        return "Unknown";
      }
      return `${address.slice(0, 6)}...${address.slice(-4)}`;
    };
  
    const formatDate = (timestamp) => {
      try {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString();
      } catch (error) {
        return "Invalid Date";
      }
    };
  
    return (
      <div className="transaction-list">
        {transactions.map((tx, index) => (
          <div key={`${tx.hash}-${index}`} className="transaction-item">
            <div className="transaction-type" data-type={tx.type.toLowerCase()}>
              {tx.type === "Received" && (
                <span className="text-green-400" title="Received Transaction">
                  ↓ {/* Unicode-стрелка вниз */}
                </span>
              )}
              {tx.type === "Sent" && (
                <span className="text-red-400" title="Sent Transaction">
                  ↑ {/* Unicode-стрелка вверх */}
                </span>
              )}
              {tx.type === "Swap" && (
                <span className="swap-icon text-blue-400" title="Swap Transaction">
                  ↔ {/* Unicode-свап */}
                </span>
              )}
              <span className="transaction-amount" data-type={tx.type.toLowerCase()}>
                {tx.type === "Received" ? "+" : tx.type === "Sent" ? "-" : "↔"}
                {tx.amount} {tx.currency}
              </span>
            </div>

            <div className="transaction-details">
              <div className="transaction-address">
                {tx.address && tx.address !== "Unknown" ? (
                  <a
                  href={`https://tonviewer.com/transaction/${tx.hash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transaction-hash hover:text-blue-400"
                  title="View transaction"
                >
                  🔗 {/* Используем Unicode-символ для ссылки */}
                </a>
                ) : (
                  <span>{formatAddress(tx.address || "Unknown")}</span>
                )}
              </div>
              
              <div className="transaction-meta">
                {/* <span className="transaction-time">{formatDate(tx.timestamp)}</span>
                <span className="transaction-fee">Fee: {tx.fee} TON</span>
                <a
                  href={`https://tonviewer.com/transaction/${tx.hash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transaction-hash hover:text-blue-400"
                  title="View transaction"
                >
                 🔗
                </a> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  const TransactionsTab = ({ transactions, loading, walletAddresses }) => {
    if (loading) {
      return <div className="loading-transactions">Loading transactions...</div>;
    }
  
    return (
      <div className="wallet-table-container">
        <table className="results-table">
          <thead>
            <tr>
              <th>Wallet Address</th>
              <th>Last 10 Transactions</th>
            </tr>
          </thead>
          <tbody>
            {walletAddresses.map((address, index) => {
              const walletTransactions = transactions
                .filter((tx) => tx.walletAddress === address)
                .sort((a, b) => b.timestamp - a.timestamp)
                .slice(0, 10);
              return (
                <tr key={address}>
                  <td className="wallet-address">
                    <WalletAddress fullAddress={address} index={index} />
                  </td>
                  <td>
                    <TransactionHistory transactions={walletTransactions} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  

  
  
  useEffect(() => {
    const tokens = new Set();
    results.forEach(wallet => {
      wallet.jettons.forEach(jetton => {
        if (jetton.name) {
          tokens.add(jetton.name);
        }
      });
    });
    setAvailableTokens(Array.from(tokens).sort());
  }, [results]);
  const clearCache = async () => {
    if (!db) {
      console.log('Database not initialized');
      return;
    }

    try {
      const tx = db.transaction('cache', 'readwrite');
      const store = tx.objectStore('cache');
      await store.clear();
      await tx.done;
      console.log('Cache cleared successfully');
      // Optional: Show visual feedback to user
      alert('Cache cleared successfully');
    } catch (error) {
      console.error('Error clearing cache:', error);
      alert('Error clearing cache');
    }
  };
  const fetchNFTs = async (walletAddress) => {
    try {
      const response = await axios.get(`https://tonapi.io/v2/accounts/${walletAddress}/nfts?limit=18&offset=0`);
      return response.data.nft_items || [];
    } catch (error) {
      console.error(`Error fetching NFTs for wallet ${walletAddress}:`, error);
      return [];
    }
  };

  const handleFetchNFTs = async () => {
    setLoadingNFTs(true);
    setShowNFTs(true);
    const nftResults = {};

    try {
      for (const address of walletAddresses) {
        if (address.trim()) {
          const nfts = await fetchNFTs(address.trim());
          nftResults[address.trim()] = nfts;
        }
      }
      setNftData(nftResults);
    } catch (error) {
      console.error('Error fetching NFTs:', error);
    } finally {
      setLoadingNFTs(false);
    }
  };
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .nft-section {
        margin-top: 20px;
        padding: 20px;
        background: #f5f5f5;
        border-radius: 8px;
      }

      .nft-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 20px;
        margin-top: 10px;
      }

      .nft-card {
        background: white;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        transition: transform 0.2s;
      }

      .nft-card:hover {
        transform: translateY(-5px);
      }

      .nft-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }

      .nft-info {
        padding: 10px;
      }

      .nft-info h4 {
        margin: 0;
        font-size: 16px;
        color: #333;
      }

      .nft-collection {
        margin: 5px 0 0;
        font-size: 14px;
        color: #666;
      }

      .wallet-nft-section {
        margin-bottom: 30px;
      }

      .wallet-nft-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background: #eee;
        border-radius: 4px;
        margin-bottom: 10px;
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);
  const tokenSuggestions = useMemo(() => {
    if (!selectedJetton.input) return availableTokens;
    return availableTokens.filter(token =>
      token.toLowerCase().includes(selectedJetton.input.toLowerCase())
    );
  }, [selectedJetton.input, availableTokens]);

  const handleSortJettons = () => {
    const sortedWallets = [...filteredResults].sort((a, b) => {
      // Find the USD value for the selected token in both wallets
      const jettonA = a.jettons.find(jetton => jetton.name === selectedJetton.exact);
      const jettonB = b.jettons.find(jetton => jetton.name === selectedJetton.exact);
  
      // If the token is not found in a wallet, assign 0
      const usdValueA = jettonA ? parseFloat(jettonA.usdValue) : 0;
      const usdValueB = jettonB ? parseFloat(jettonB.usdValue) : 0;
  
      // Sort in descending order by the USD value of the selected token
      return sortedJettonsDescending ? usdValueB - usdValueA : usdValueA - usdValueB;
    });
  
    // Toggle the sorting order for the next click
    setSortedJettonsDescending(!sortedJettonsDescending);
    setFilteredResults(sortedWallets);
  };
  
  

  
  

  useEffect(() => {
    const initializeDB = async () => {
      const database = await openDB('ton-wallet-db', 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains('cache')) {
            db.createObjectStore('cache', { keyPath: 'key' });
          }
        }
      });
      setDb(database); // Устанавливаем экземпляр базы данных
    };
    initializeDB();
  }, []);

  const setCache = async (key, data) => {
    if (!db) return; // Проверяем, что база данных инициализирована
    const now = Date.now();
    const tx = db.transaction('cache', 'readwrite');
    const store = tx.objectStore('cache');
    await store.put({ key, data, timestamp: now });
    await tx.done;
  };

  const getCache = async (key) => {
    if (!db) return null; // Проверяем, что база данных инициализирована
    const tx = db.transaction('cache', 'readonly');
    const store = tx.objectStore('cache');
    const cached = await store.get(key);
    if (cached && Date.now() - cached.timestamp < ttl) {
      return cached.data;
    }
    return null;
  };

  const fetchWithCache = async (key, fetchFunc) => {
    const cachedData = await getCache(key);
    if (cachedData) {
      console.log(`Cache hit for key: ${key}`);
      return cachedData; // Возвращаем данные из кэша
    }
    console.log(`Cache miss for key: ${key}. Fetching from API...`);
    const data = await fetchFunc();
    await setCache(key, data); // Кэшируем новые данные
    return data;
  };
  


  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  const fetchJettons = async (walletAddress) => {
    try {
      const response = await axios.get(`https://tonapi.io/v2/accounts/${walletAddress}/jettons?currencies=ton,usd,rub&supported_extensions=custom_payload`);
      return response.data.balances || [];
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.warn(`404 error for wallet: ${walletAddress}, skipping.`);
        return [];
      } else {
        throw error;
      }
    }
  };

  const formatPrice = (price) => {
    if (!price) return '0';
    let formattedPrice = parseFloat(price).toFixed(4);
    if (formattedPrice < 0.001) {
      formattedPrice = parseFloat(price).toFixed(6);
    }
    return formattedPrice;
  };

  const formatJettonBalance = (jetton) => {
    let balance = jetton.balance;
    const decimals = jetton.jetton?.decimals || 0;
    if (decimals > 0) {
      balance = balance / Math.pow(10, decimals);
    }
    return parseFloat(balance).toFixed(4); // Show up to 4 decimal digits
  };
  

  const formatAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };


  const formatTonBalance = (nanoton) => {
    return (parseInt(nanoton) / 10 ** 9).toFixed(4); // Show up to 4 decimal digits
  };
  

  const calculateUSDValue = (amount, price) => {
    return amount * (price || 0);
  };

  const handleSubmit = async () => {
    console.log('Starting wallet balance and transaction check...');
    setLoading(true);
    setLoadingTransactions(true);
    setResults([]);
    setTransactions([]);
    setProgress({ processedCount: 0, totalWallets: walletAddresses.length });
    
    let totalUSDValueAcrossAllTokens = 0;
    const jettonBalances = {};
    const jettonBalancesInUSD = {};
    const allTransactions = [];
  
    try {
      const results = [];
      
      for (let i = 0; i < walletAddresses.length; i++) {
        const walletAddress = walletAddresses[i].trim();
        if (!walletAddress) continue;
  
        const cacheKey = walletAddress;
        let attempt = 0;
        let success = false;
  
        while (!success && attempt < 5) {
          try {
            // Check cache for wallet data
            const cachedData = await getCache(cacheKey);
            if (cachedData) {
              console.log(`Using cached data for wallet: ${walletAddress}`);
              results.push(cachedData);
              totalUSDValueAcrossAllTokens += parseFloat(cachedData.totalUSDValue);
              console.log(`Cached total USD value: ${cachedData.totalUSDValue}`);
              
              // Fetch fresh transactions even if we have cached balance data
              const transactions = await fetchTransactions(walletAddress);
              allTransactions.push(...transactions);
              
              success = true;
              break;
            }
  
            // Fetch fresh data if not in cache
            await delay(500 * (attempt + 1));
  
            // Fetch balance data
            const balanceResponse = await fetchWithCache(cacheKey + '_balance', async () => {
              return axios.get(`https://tonapi.io/v2/blockchain/accounts/${walletAddress}`).then(res => res.data);
            });
  
            // Fetch TON price
            const tonPriceResponse = await fetchWithCache('ton_price', async () => {
              return axios.get('https://api.coingecko.com/api/v3/simple/price?ids=the-open-network&vs_currencies=usd')
                .then(res => res.data['the-open-network'].usd);
            });
  
            const tonBalance = formatTonBalance(balanceResponse.balance);
            const tonUSDValue = calculateUSDValue(parseFloat(tonBalance), tonPriceResponse);
  
            // Fetch jettons
            const jettonsResponse = await fetchWithCache(cacheKey + '_jettons', async () => {
              return fetchJettons(walletAddress);
            });
  
            let walletUSDValue = tonUSDValue;
  
            // Process jettons
            const jettons = jettonsResponse.map(jetton => {
              const balance = parseFloat(formatJettonBalance(jetton));
              const price = parseFloat(jetton.price?.prices?.USD || 0);
              const usdValue = calculateUSDValue(balance, price);
              walletUSDValue += usdValue;
  
              if (!jettonBalances[jetton.jetton?.name]) {
                jettonBalances[jetton.jetton?.name] = 0;
                jettonBalancesInUSD[jetton.jetton?.name] = 0;
              }
              jettonBalances[jetton.jetton?.name] += balance;
              jettonBalancesInUSD[jetton.jetton?.name] += usdValue;
  
              return {
                name: jetton.jetton?.name || 'Unknown',
                price: formatPrice(price),
                count: balance,
                usdValue: usdValue.toFixed(2),
              };
            });
  
            // Find and process USDT jetton
            const usdtJetton = jettons.find(jetton => 
              jetton.name.toLowerCase().includes('usdt') || 
              jetton.name.toLowerCase().includes('tether')
            ) || {
              name: 'Tether USD',
              price: '1',
              count: 0,
              usdValue: '0',
            };
  
            // Process other top jettons
            const otherJettons = jettons
              .filter(jetton => jetton.name !== 'TON' && jetton.name !== usdtJetton.name)
              .sort((a, b) => b.usdValue - a.usdValue)
              .slice(0, 5);
  
            // Combine all jettons
            const finalJettons = [
              {
                name: 'TON',
                price: formatPrice(tonPriceResponse),
                count: parseFloat(tonBalance),
                usdValue: tonUSDValue.toFixed(2),
              },
              usdtJetton,
              ...otherJettons,
            ];
  
            totalUSDValueAcrossAllTokens += walletUSDValue;
  
            // Prepare wallet result
            const result = {
              walletAddress: `Wallet ${i + 1}: ${formatAddress(walletAddress)}`,
              jettons: finalJettons,
              totalUSDValue: walletUSDValue.toFixed(2),
            };
  
            // Cache the result and fetch transactions
            await setCache(cacheKey, result);
            const transactions = await fetchTransactions(walletAddress);
            allTransactions.push(...transactions);
  
            results.push(result);
            success = true;
          } catch (error) {
            attempt++;
            if (attempt >= 5) {
              console.error(`Failed to fetch data for wallet: ${walletAddress} after multiple attempts.`);
              results.push({
                walletAddress: `Wallet ${i + 1}: ` + formatAddress(walletAddress),
                jettons: [{ name: 'Error', price: '0', count: 0, usdValue: '0' }],
                totalUSDValue: '0',
              });
            }
          }
        }
  
        setProgress({ processedCount: i + 1, totalWallets: walletAddresses.length });
      }
  
      console.log(`Total USD value across all wallets: ${totalUSDValueAcrossAllTokens.toFixed(2)}`);
      setResults(results);
      setFilteredResults(results);
      setTotalBalance(totalUSDValueAcrossAllTokens);
      setTotalJettonBalances(jettonBalances);
      setTransactions(allTransactions);
  
    } catch (error) {
      console.error('Error fetching wallet balances and transactions:', error);
    } finally {
      setLoading(false);
      setLoadingTransactions(false);
    }
  };
  
  const fetchTransactions = async (walletAddress) => {
    try {
      const response = await axios.get(
        `https://tonapi.io/v2/blockchain/accounts/${walletAddress}/transactions?limit=10`
      );
  
      return (response.data.transactions || []).map((tx) => {
        // Check for swap transaction by looking at operation type and messages
        const isSwap = tx.description?.operation_type === 'dex_exchange' || 
                      (tx.in_msg?.value && tx.out_msgs?.[0]?.value && 
                       tx.in_msg?.value !== tx.out_msgs[0]?.value);
  
        if (isSwap) {
          // Get sent and received amounts
          const outMsg = tx.out_msgs?.[0];
          const inMsg = tx.in_msg;
          
          // Format amounts with proper tokens
          const sentAmount = outMsg?.jetton ? 
            `${((outMsg.value || 0) / 1e9).toFixed(4)} ${outMsg.jetton.name}` :
            `${((outMsg.value || 0) / 1e9).toFixed(4)} TON`;
            
          const receivedAmount = inMsg?.jetton ?
            `${((inMsg.value || 0) / 1e9).toFixed(4)} ${inMsg.jetton.name}` :
            `${((inMsg.value || 0) / 1e9).toFixed(4)} TON`;
  
          return {
            type: "Swap",
            sentAmount,
            receivedAmount,
            address: tx.in_msg?.source || tx.out_msgs?.[0]?.destination || "Unknown",
            timestamp: tx.utime,
            hash: tx.hash,
            comment: tx.in_msg?.decoded_body?.text || "",
            fee: ((tx.fee || 0) / 1e9).toFixed(4),
            walletAddress
          };
        }
  
        // Handle regular transaction (sent/received)
        const isIncoming = Boolean(tx.in_msg?.source);
        const value = isIncoming ? tx.in_msg?.value : tx.out_msgs?.[0]?.value || 0;
        const token = isIncoming ? tx.in_msg?.jetton : tx.out_msgs?.[0]?.jetton;
        
        return {
          type: isIncoming ? "Received" : "Sent",
          address: isIncoming ? tx.in_msg?.source : tx.out_msgs?.[0]?.destination || "Unknown",
          amount: ((value || 0) / 1e9).toFixed(4),
          currency: token?.name || "TON",
          timestamp: tx.utime,
          hash: tx.hash,
          comment: tx.in_msg?.decoded_body?.text || "",
          fee: ((tx.fee || 0) / 1e9).toFixed(4),
          walletAddress
        };
      });
    } catch (error) {
      console.error(`Error fetching transactions for wallet ${walletAddress}:`, error);
      return [];
    }
  };
  
  useEffect(() => {
    if (selectedJetton.input) {
      let totalFilteredJettonBalance = 0;
      let totalFilteredJettonUSD = 0;
      
      const filtered = results.map(walletData => {
        const filteredJettons = walletData.jettons.filter(jetton => {
          // Если есть точное совпадение, используем его
          if (selectedJetton.exact) {
            return jetton.name === selectedJetton.exact;
          }
          // Иначе фильтруем по частичному совпадению
          return jetton.name.toLowerCase().includes(selectedJetton.input.toLowerCase());
        });

        filteredJettons.forEach(jetton => {
          totalFilteredJettonBalance += parseFloat(jetton.count);
          totalFilteredJettonUSD += parseFloat(jetton.usdValue);
        });

        return {
          ...walletData,
          jettons: [
            walletData.jettons.find(j => j.name === 'TON'),
            walletData.jettons.find(j => j.name === 'Tether USD'),
            ...filteredJettons
          ].filter(j => j !== undefined)
        };
      });

      setFilteredResults(filtered);
      setTotalJettonBalances({
        [selectedJetton.exact || selectedJetton.input]: totalFilteredJettonBalance,
        [`${selectedJetton.exact || selectedJetton.input}_usd`]: totalFilteredJettonUSD
      });
    } else {
      const filtered = results.map(walletData => ({
        ...walletData,
        jettons: [
          walletData.jettons.find(j => j.name === 'TON'),
          walletData.jettons.find(j => j.name === 'Tether USD'),
          ...walletData.jettons.filter(jetton =>
            jetton.name !== 'TON' && jetton.name !== 'Tether USD'
          ).slice(0, 5)
        ].filter(j => j !== undefined)
      }));

      setFilteredResults(filtered);
    }
  }, [selectedJetton, results]);
  
  const handleSortByBalance = () => {
    if (sortedDescending) {
      setFilteredResults([...filteredResults].sort((a, b) => b.totalUSDValue - a.totalUSDValue));
    } else {
      setFilteredResults([...filteredResults].sort((a, b) => parseInt(a.walletAddress.match(/Wallet (\d+):/)[1]) - parseInt(b.walletAddress.match(/Wallet (\d+):/)[1])));
    }
    setSortedDescending(!sortedDescending);
  };

  const JettonList = ({ jettons }) => (
    <div className="jettons-list">
      {jettons.map((jetton, index) => (
        <div key={index} className="jetton-item">
          <div className="jetton-name">{jetton.name}</div>
          <div className="jetton-balance">{parseFloat(jetton.count).toFixed(4)}</div> {/* Only 4 decimals */}
          <div className="jetton-price">${parseFloat(jetton.price).toFixed(4)}</div> {/* Only 4 decimals */}
          <div className="jetton-usd-value">${parseFloat(jetton.usdValue).toFixed(4)}</div> {/* Only 4 decimals */}
        </div>
      ))}
    </div>
  );
  const WalletAddress = ({ fullAddress, index }) => {
    const [showCopied, setShowCopied] = useState(false);
  
    const formatAddress = (address) => {
      if (!address) return '';
      return `${address.slice(0, 6)}...${address.slice(-4)}`;
    };
  
    const handleCopy = async (e) => {
      e.preventDefault(); // Prevent the link from opening when copying
      try {
        await navigator.clipboard.writeText(fullAddress);
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 2000);
      } catch (err) {
        console.error('Failed to copy:', err);
      }
    };
  
  
    return (
      <div className="wallet-address-container" style={{ position: 'relative', display: 'inline-block' }}>
            <a
              href={`https://tonviewer.com/${fullAddress}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
              style={{ 
                cursor: 'pointer',
                color: '#2196f3',
                textDecoration: 'none',
                position: 'relative'
              }}
            >
              <span onClick={handleCopy} style={{ marginRight: '4px' }}>
                Wallet {index + 1}: {formatAddress(fullAddress)}
              </span>
              <span className="external-link" style={{ fontSize: '12px', marginLeft: '4px' }}>↗</span>
            </a>
            {showCopied && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '100%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  fontSize: '12px',
                  whiteSpace: 'nowrap',
                  zIndex: 1000
                }}
              >
                Copied!
              </div>
            )}
          </div>
    );
  };

  const TokensTab = () => (
    <div className="tokens-content">
      <div className="controls-section">
        <div className="min-balance-input">
          <label>Filter by Jetton: </label>
          <TokenAutocomplete
            value={selectedJetton.input}
            onChange={setSelectedJetton}
            suggestions={tokenSuggestions}
          />
        </div>

        <button onClick={handleSortJettons} disabled={loading} className="check-button">
          Sort Tokens by USD Value {sortedJettonsDescending ? '(Ascending)' : '(Descending)'}
        </button>

        <button onClick={handleSortByBalance} disabled={loading} className="check-button">
          Sort by Balance {sortedDescending ? '(Ascending)' : '(Descending)'}
        </button>
      </div>

      <div className="balance-info">
        <div className="total-balance">
          Total Balance in USD: ${totalBalance.toFixed(4)}
        </div>

        {selectedJetton && (
          <div className="total-jetton-balances">
            <h2>Total Jetton Balances</h2>
            {Object.keys(totalJettonBalances).map((jettonName, index) => (
              <div key={index} className="total-balance">
                <span>{jettonName}: </span>
                <span>{parseFloat(totalJettonBalances[jettonName]).toFixed(4)}</span>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="wallet-table-container">
        <table className="results-table">
          <thead>
            <tr>
              <th>Total USDT Value</th>
              <th>Wallet Address</th>
              <th>Jettons</th>
            </tr>
          </thead>
          <tbody>
          {filteredResults.map((walletData, index) => {
            // Извлекаем полный адрес из строки walletAddress
            const fullAddress = walletAddresses[index].trim();
            return (
              <tr key={index}>
                <td className="total-usd-value">${walletData.totalUSDValue}</td>
                <td>
                  <WalletAddress 
                    fullAddress={fullAddress} 
                    index={index}
                  />
                </td>
                <td className="jettons-cell">
                  <JettonList jettons={walletData.jettons} />
                </td>
              </tr>
            );
          })}
        </tbody>
        </table>
      </div>
    </div>
  );
  const WalletAddressNFT = ({ address }) => {
    const [showCopied, setShowCopied] = useState(false);
  
    const formatAddress = (addr) => {
      if (!addr) return '';
      return `${addr.slice(0, 6)}...${addr.slice(-4)}`;
    };
  
    const handleCopy = async (e) => {
      e.preventDefault();
      try {
        await navigator.clipboard.writeText(address);
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 2000);
      } catch (err) {
        console.error('Failed to copy:', err);
      }
    };
  
    return (
      <div className="wallet-address-container" style={{ position: 'relative', display: 'inline-block' }}>
        <a
          href={`https://tonviewer.com/${address}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ 
            cursor: 'pointer',
            color: '#2196f3',
            textDecoration: 'none',
            position: 'relative'
          }}
        >
          <span onClick={handleCopy} style={{ marginRight: '4px' }}>
            {formatAddress(address)}
          </span>
          <span className="external-link" style={{ fontSize: '12px', marginLeft: '4px' }}>↗</span>
        </a>
        {showCopied && (
          <div
            style={{
              position: 'absolute',
              bottom: '100%',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: '#4CAF50',
              color: 'white',
              padding: '4px 8px',
              borderRadius: '4px',
              fontSize: '12px',
              whiteSpace: 'nowrap',
              zIndex: 1000
            }}
          >
            Copied!
          </div>
        )}
      </div>
    );
  };
  const NFTsTab = () => (
    <div className="nft-content">
      {loadingNFTs ? (
        <div className="loading-nfts">Loading NFTs...</div>
      ) : (
        <div className="nft-section">
          <div className="wallet-table-container">
            <table className="results-table">
              <thead>
                <tr>
                  <th>Wallet Address</th>
                  <th>NFTs</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(nftData).map(([address, nfts]) => (
                  <tr key={address} className="wallet-nft-row">
                    <td className="wallet-address">
                      <WalletAddressNFT address={address} />
                      <span className="nft-count">({nfts.length} NFTs)</span>
                    </td>
                    <td className="wallet-nfts">
                      <NFTDisplay nfts={nfts} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
  
  

  return (
    <div className="ton-wallet-checker">
      <div className="header-section">
        <h1>TON Wallet Balance Checker</h1>
      </div>
      <button 
          onClick={clearCache} 
          className="check-button"
          style={{
            marginLeft: '10px',
            backgroundColor: '#dc3545',
            color: 'white',
            padding: '8px 16px',
            borderRadius: '4px',
            border: 'none',
            cursor: 'pointer'
          }}
        >
          Clear Cache
        </button>
      <div className="input-section">
        <textarea
          rows="10"
          value={walletAddresses.join('\n')}
          onChange={(e) => {
            const addresses = e.target.value.split('\n').filter(address => address.trim() !== '');
            setWalletAddresses(addresses);
          }}
          placeholder="Enter wallet addresses, one per line"
          className="wallet-input"
        />

        <button onClick={handleSubmit} disabled={loading} className="check-button">
          {loading ? 'Loading...' : 'Check Balances'}
        </button>
      </div>

      {(loading || progress.processedCount > 0) && (
        <div className="progress-section">
          <h2>Progress</h2>
          <p>{`Processed: ${progress.processedCount} / ${progress.totalWallets}`}</p>
        </div>
      )}

<div className="tabs">
        <button 
          className={`tab-button ${activeTab === 'tokens' ? 'active' : ''}`}
          onClick={() => setActiveTab('tokens')}
        >
          Tokens
        </button>
        <button 
          className={`tab-button ${activeTab === 'nfts' ? 'active' : ''}`}
          onClick={() => {
            setActiveTab('nfts');
            if (!loadingNFTs && Object.keys(nftData).length === 0) {
              handleFetchNFTs();
            }
          }}
        >
          NFTs
        </button>
        <button 
          className={`tab-button ${activeTab === 'transactions' ? 'active' : ''}`}
          onClick={() => setActiveTab('transactions')}
        >
          Transactions
        </button>
      </div>

      {activeTab === 'tokens' && <TokensTab />}
      {activeTab === 'nfts' && <NFTsTab />}
      {activeTab === 'transactions' && (
        <TransactionsTab 
          transactions={transactions}
          loading={loadingTransactions}
          walletAddresses={walletAddresses}
        />
      )}
    </div>
  );
};



export default TonWalletChecker;
